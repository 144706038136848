import React, { useLayoutEffect } from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import { Location } from '@reach/router'

const Confirmation = props => {
  return (
    <Location>
      {({ location, navigate }) => {
        // console.log('anv', location)
        let emailFromURL = 'info@gvdrenovations.com'

        var currentTime = new Date()
        currentTime.setDate(currentTime.getDate() + parseInt(2))

        return (
          <Layout pageProps={props}>
            {' '}
            <Helmet title={'Confirmation'}>
              <meta name="robots" />
              <script src="https://apis.google.com/js/platform.js?onload=renderOptIn"></script>
              <script>
                {`
            (window.renderOptIn = function() {
        
              window.gapi.load('surveyoptin', function() {
                
                window.gapi.surveyoptin.render({
                  // REQUIRED

                  merchant_id: '229748028',
                  order_id: '${new Date().valueOf()}',
                  email: '${
                    location && location.search && location.search.substring(7)
                      ? location && location.search.substring(7)
                      : emailFromURL
                  }',
                  delivery_country: 'US',
                  estimated_delivery_date: '${currentTime
                    .toISOString()
                    .substring(0, 10)}',
                  // OPTIONAL
                
                  opt_in_style: 'CENTER_DIALOG',
                })
              })
            })
          `}
              </script>
            </Helmet>
          </Layout>
        )
      }}
    </Location>
  )
}

export default Confirmation
